const winnerColor = '#f27800';
const timeoutColor = '#258dcb';
const deadColor = '#dd1d1d';

const winIcon = () => "data:image/svg+xml;base64," +
  window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path
    d="M 355.55548,0 H 156.44439 c -23.55556,0 -42.75558,21.799978 -41.86668,48.199993 0.17685,5.300016 0.35552,10.599975 0.62232,15.799985 H 21.333351 C 9.511117,63.999978 0,74.70001 0,88.000004 0,180.60009 29.777806,244.99997 69.777803,288.70004 c 39.377767,43.09998 87.377787,64.79995 122.755577,75.80004 20.79998,6.49989 35.02221,25.99993 35.02221,45.59986 0,20.90014 -15.11111,37.90011 -33.6889,37.90011 h -23.2001 c -15.73333,0 -28.44444,14.29995 -28.44444,32.00003 0,17.69995 12.71111,31.99992 28.44444,31.99992 h 170.66669 c 15.73333,0 28.44444,-14.29997 28.44444,-31.99992 0,-17.70008 -12.71111,-32.00003 -28.44444,-32.00003 h -23.19997 c -18.57783,0 -33.68891,-16.99997 -33.68891,-37.90011 0,-19.59993 14.1333,-39.19992 35.02218,-45.59986 35.46665,-11.00009 83.46669,-32.70006 122.84447,-75.80004 C 482.22219,244.99997 512,180.60009 512,88.000004 512,74.70001 502.48882,63.999978 490.66668,63.999978 h -93.86665 c 0.26831,-5.20001 0.44456,-10.399971 0.62232,-15.799985 C 398.31116,21.799978 379.11119,0 355.5556,0 Z M 43.466602,112.00003 h 75.022238 c 8.08886,90.09999 25.95555,150.29994 46.13333,190.60006 C 142.48883,291.60003 119.46662,276.1 99.555484,254.29996 71.11105,223.20007 47.999926,178.3 43.555481,112.00003 Z M 412.5333,254.29996 c -19.91113,21.80004 -42.93331,37.30007 -65.06672,48.30008 20.17781,-40.30007 38.04447,-100.50002 46.13346,-190.60001 h 75.02215 C 464.08889,178.3 440.97777,223.19999 412.62221,254.29996 Z"
    style="display:inline;fill:${winnerColor};fill-opacity:1;stroke-width:0.942803"/></svg>`);
const timeoutIcon = (color) => "data:image/svg+xml;base64," +
  window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path
    d="M256 0a256 256 0 1 1 0 512A256 256 0 1 1 256 0zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"
    style="fill:${timeoutColor};fill-opacity:1" />
  <path
    d="m 347.56813,343.4219 c -1.19464,-0.22851 -3.40423,-0.97442 -4.9102,-1.65757 -1.50598,-0.68316 -25.03873,-16.11301 -52.295,-34.28856 -53.35648,-35.5802 -52.70639,-35.1017 -55.5467,-40.88583 -2.80056,-5.7032 -2.68216,-2.23055 -2.68216,-78.66317 0,-76.33936 -0.11865,-72.84565 2.66605,-78.50218 2.65646,-5.39602 7.36148,-9.558982 13.38147,-11.839777 2.45069,-0.928493 3.68069,-1.101835 7.81841,-1.101835 4.13772,0 5.36772,0.173342 7.81841,1.101835 6.01999,2.280795 10.72501,6.443757 13.38147,11.839777 2.76919,5.62502 2.6653,2.85021 2.6738,71.41934 l 0.008,62.55273 43.25354,28.80401 c 23.78945,15.8422 44.06747,29.53934 45.06227,30.43808 5.8756,5.30826 8.82219,14.65094 7.02583,22.27668 -2.97097,12.61216 -15.30826,20.86817 -27.65494,18.50647 z"
    style="fill:${color};stroke:#fffffd;stroke-width:0.337423;stroke-dashoffset:5.66928;fill-opacity:1"/></svg>`);
const deathIcon = (color) => "data:image/svg+xml;base64," +
  window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path
    d="M416 398.9c58.5-41.1 96-104.1 96-174.9C512 100.3 397.4 0 256 0S0 100.3 0 224c0 70.7 37.5 133.8 96 174.9c0 .4 0 .7 0 1.1l0 64c0 26.5 21.5 48 48 48l48 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 64 0 0-48c0-8.8 7.2-16 16-16s16 7.2 16 16l0 48 48 0c26.5 0 48-21.5 48-48l0-64c0-.4 0-.7 0-1.1zM96 256a64 64 0 1 1 128 0A64 64 0 1 1 96 256zm256-64a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"
    style="fill:${deadColor};fill-opacity:1" />
  <path
    d="m 154.44813,319.53553 c -4.00233,-0.39503 -10.41075,-1.71914 -14.06103,-2.90528 -22.51496,-7.31616 -39.51477,-27.16637 -43.31126,-50.57335 -0.838358,-5.16883 -0.838358,-14.94497 0,-20.1138 1.477346,-9.10846 5.17304,-18.26135 10.57985,-26.20246 3.47697,-5.10671 10.45807,-12.15394 15.68707,-15.83565 7.25007,-5.10476 16.27087,-8.90469 25.3024,-10.65841 6.25659,-1.21489 17.36709,-1.11209 23.65824,0.21889 25.68352,5.43372 44.64963,24.3138 50.27589,50.04782 1.17946,5.39475 1.17946,19.57867 0,24.97342 -4.64087,21.22697 -18.92334,38.47414 -38.4888,46.47816 -9.14817,3.74242 -20.42176,5.48074 -29.64236,4.57066 z"
    style="fill:${color};stroke:#fffffd;stroke-width:0.337423;stroke-dashoffset:5.66928"/>
  <path
    d="m 344.68434,319.36892 c -27.56983,-3.47143 -49.26543,-23.44697 -55.26363,-50.88221 -1.17946,-5.39475 -1.17946,-19.57867 0,-24.97342 5.68107,-25.98473 25.37395,-45.32538 51.16865,-50.25344 5.88711,-1.12473 16.98047,-1.1298 22.76548,-0.0104 26.73337,5.17291 47.26431,26.15147 51.56932,52.69365 0.83836,5.16883 0.83836,14.94497 0,20.1138 -1.47734,9.10846 -5.17304,18.26135 -10.57985,26.20246 -3.38511,4.97179 -10.40033,12.09546 -15.43007,15.66862 -7.30602,5.19024 -16.683,9.14975 -25.5594,10.79269 -4.68123,0.86644 -14.28844,1.20002 -18.6705,0.64825 z"
    style="fill:${color};stroke:#fffffd;stroke-width:0.337423;stroke-dashoffset:5.66928"/></svg>`);
const wonderWinIcon = () => '/static/wonder_active.png';
const foodIcon = () => '/files/images/resource0.png';
const woodIcon = () => '/files/images/resource1.png';
const ironIcon = () => '/files/images/resource2.png';

const icons = {
  win: { getSrc: winIcon, width: 12, height: 12 },
  death: { getSrc: deathIcon, width: 12, height: 12 },
  wonder: { getSrc: wonderWinIcon, width: 14, height: 14 },
  timeout: { getSrc: timeoutIcon, width: 12, height: 12  },
  food: { getSrc: foodIcon, width: 16, height: 16  },
  wood: { getSrc: woodIcon, width: 16, height: 16  },
  iron: { getSrc: ironIcon, width: 16, height: 16  }
}

export const resolveChartImage = (iconIdentifierString, color) => {
  const icon = icons[iconIdentifierString];
  const img = new Image(icon.width, icon.height);
  img.src = icon.getSrc(color);
  return img;
};